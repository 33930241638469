<template>
  <div>
      <h1>Bind</h1>
      <v-btn @click="getInvitationList()">SHOW List</v-btn>
      <v-btn @click="createInvitation()">Create</v-btn>
      <hr>

      <v-container>
        <v-row v-for="invitation in invitations" :key="invitation.id">
          <p class="mr-10">{{ invitation.title }}</p >
          <v-btn @click="activateInvitation(invitation.id)" :color="invitation.is_active ? 'primary' : 'normal'">Activate</v-btn>
          <v-btn @click="deactivateInvitation(invitation.id)" :color="invitation.is_active ? 'primary' : 'normal'">Deactivate</v-btn>
          <v-btn @click="deleteInvitation(invitation.id)">Delete</v-btn>
          <hr>
        </v-row>
      </v-container>
        <!-- <v-btn @click="activateInvitation(invitation.id)" v-for="invitation in invitations" :key="invitation.id" :color="invitation.is_active ? 'primary' : 'normal'">
          
        </v-btn> -->
 
      
  </div>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL;

export default {
  name: "Invitation",

  components: {},
  data() {
    return {
      invitations: []
    }
  },
  mounted () {
      // let params = new URL(location.href).searchParams;
      this.getInvitationList();
      // TODO: show bot list 
  },
  methods: {
    async getInvitationList() {
        try {
            let resp = await axios.get(host_url + "/invitations/");
            console.log(resp.data);
             this.invitations = Object.assign({}, this.invitations, resp.data)
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async createInvitation() {
        try {
            let payload = {
              "title": "MGM_" + (Math.random() + 1).toString(36).substring(4),
              "desc": "very funny MGM",
              "trigger_num": 1,
              "reward_new": "LINE_POINT_1",
              "reward_old": "LINE_POINT_5",
              "start_at": "2021-08-17 10:10:00",
              "end_at": "2021-09-17 10:10:00",
              
            }
            let resp = await axios.post(host_url + "/invitations/", payload);
            console.log(resp.data);
            this.getInvitationList();
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async updateInvitation(id) {
        try {
            let payload = {
              "desc": "new description~",
            }
            let resp = await axios.patch(host_url + `/invitations/${id}/`, payload);
            console.log(resp.data);
            this.getInvitationList();
        } catch (error) {
            console.log(error);
            throw new Error('Get bots Fail')
        }
    },
    async deleteInvitation(id) {
        try {
            let payload = {
              "desc": "new description~",
            }
            let resp = await axios.delete(host_url + `/invitations/${id}/`);
            console.log(resp.data);
            this.getInvitationList(); // === UI not update ===
        } catch (error) {
            console.log(error);
            throw new Error('delete bots Fail')
        }
    },
    async activateInvitation(id) {
        try {
            let resp = await axios.post(host_url + `/invitations/${id}/activate/`);
            console.log(resp.data);

            this.getInvitationList();

        } catch (error) {
            console.log(error);
            throw new Error('activate invitation Fail')
        }
    },
    async deactivateInvitation(id) {
        try {
            let resp = await axios.post(host_url + `/invitations/${id}/deactivate/`);
            console.log(resp.data);

            this.getInvitationList();

        } catch (error) {
            console.log(error);
            throw new Error('activate invitation Fail')
        }
    }
  },
};
</script>
